<template>
  <div class="my_order">
    <div class="title">我的订单</div>
    <div>
      <van-pull-refresh v-model="refreshing">
        <van-list
          offset="2"
          v-model="loading"
          :finished="finished"
          :finished-text="finishText"
          @load="onLoad"
        >
          <van-cell v-for="item in tableData" :key="item.ros_id">
            <p>
              <span class="list_title">订单号：</span>
              <span class="list_des">{{item.order_id}}</span>
            </p>
            <p>
              <span class="list_title">商品名称：</span>
              <span class="list_des">{{item.product_name}}</span>
            </p>
            <p>
              <span class="list_title">付款类型：</span>
              <span class="list_des">{{item.payment_type}}</span>
            </p>
            <p>
              <span class="list_title">金额：</span>
              <span class="list_des">{{item.total_fee/100}}</span>
            </p>
            <p>
              <span class="list_title">手续费：</span>
              <span class="list_des">{{item.surcharge/100}}</span>
            </p>
            <p>
              <span class="list_title">货币：</span>
              <span class="list_des">{{item.currency}}</span>
            </p>
            <p>
              <span class="list_title">订单创建时间：</span>
              <span class="list_des">{{item.created_at}}</span>
            </p>
            <p>
              <span class="list_title">确认已支付时间：</span>
              <span class="list_des">{{item.paid_at}}</span>
            </p>
            <p>
              <span class="list_title">订单支付状态：</span>
              <span class="list_des">{{(item.channel == 'Paypal') ? (item.status != 1 ? statusStr[3]: statusStr[item.status]) : (statusStr[item.status])}}</span>
            </p>
            <p>
              <span class="list_title">是否去支付：</span>
              <span class="list_des">
                <a style="color:green;cursor:pointer" @click="()=>goPay(item)">{{ item.status === 0 && item.channel != 'Paypal'  ? '去支付' : '' }}</a>
              </span>
            </p>
          </van-cell>
        </van-list>
      </van-pull-refresh>
      <!--*****************************  二维码弹框   **********************************-->
      <van-overlay :show="isShow" @click="hide">
        <div class="wrapper">
          <div class="block1" v-if="!status">
            <div class="qr_inner_box">
              <h1>{{channel=='Wechat'?'微信支付':'支付宝支付'}}</h1>
              <div id="paycode"></div>
            </div>
          </div>
          <!--*********  支付成功后的弹窗  ******** -->
          <div class="block" style="z-index:1000" v-else>
            <div class="inner_box">
              <i
                style="font-size:50px"
                :class="{'el-icon-circle-check':status==1,'el-icon-circle-close':status==2||status==3}"
              ></i>
              <p style="font-size:20px;margin-top:20px;">{{statusStr[status]}}</p>
            </div>
          </div>
        </div>
      </van-overlay>
      <!--****************************  二维码弹框   ********************************** -->
    </div>
  </div>
</template>
<script>
import { getMyOrderListApi } from "api/AdminCenter";
import { getOrderStatusApi } from "api/Recharge";
import QRcode from "qrcodejs2";
export default {
  data() {
    return {
      current_page: 0,
      last_page: 1,
      total: 1,
      size: 15,
      tableData: [], //列表
      type: {
        1: "APP客户端",
        2: "加速盒",
        3: "家庭加速盒",
        4: "直连账户",
        5: "账户充值",
        6: "APP+家庭加速盒",
      },
      statusStr: {
        0: "待支付",
        1: "支付成功",
        2: "支付失败",
        3: "订单关闭"
      },
      status: "", //付款后返回状态
      isShow: false, //控制二维码弹框
      channel: "", //支付方式
      timer: null,
      loading: false,
      finished: false,
      refreshing: false,
      finishText: ""
    };
  },
  methods: {
    //隐藏弹窗
    hide() {
      this.isShow = false;
      this.status = "";
    },
    //加载
    onLoad() {
      if (this.current_page < this.last_page) {
        this.getList({ page: this.current_page + 1, size: this.size });
      } else {
        this.finished = true;
      }
    },
    //去支付
    goPay(data) {
      console.log(this.status);
      const { code_url, channel, order_id } = data;
      this.channel = channel;
      this.isShow = true;
      setTimeout(() => {
        new QRcode("paycode", {
          text: code_url,
          width: 200, //图像宽度
          height: 200, //图像高度
          colorDark: "#517cfc",
          colorLight: "#ffffff",
          correctLevel: QRcode.CorrectLevel.H
        });
        this.timer = setInterval(() => this.getResult(order_id), 5000);
      }, 1000);
    },
    //查看支付订单状态
    getResult(order_id) {
      if (this.isShow) {
        getOrderStatusApi({ order_id }).then(res => {
          if (res.code == 0) {
            const { status } = res.data;
            this.getList({ size: this.size });
            //订单状态:0待支付/1支付成功/2支付失败/3订单关闭
            if (status != 0) {
              this.status = status;
              clearInterval(this.timer);
            }
          } else {
            clearInterval(this.timer);
            this.isShow = false;
          }
        });
      }
    },
    //关闭弹窗
    closeMask() {
      this.isShow = false;
    },
    //获取列表数据
    getList(values) {
      getMyOrderListApi(values).then(res => {
        if (res.code == 0) {
          const { current_page, total, data, last_page } = res.data;
          data.map((item, index) => {
            item.index = index;
          });
          this.tableData = [...this.tableData, ...data];
          this.current_page = current_page;
          this.total = total;
          this.last_page = last_page;
          this.loading = false;
          this.finishText = res.data.length > 0 ? "没有更多了" : "暂无数据";
        }
      });
    }
  },
  mounted() {
    // this.getList({ size: this.size });
  }
};
</script>
<style lang="scss" scoped>
.my_order {
  background: #fff;
  padding: 16px;
  padding-top: 80px;
  padding-bottom: 300;
  .title {
    padding-bottom: 35px;
    font-size: 30px;
    color: #517cfc;
  }
  .van-pull-refresh {
    p {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      .list_title {
        font-size: 16px;
        color: #7e7e7e;
      }
      .list_des {
        font-size: 16px;
        color: #202020;
      }
    }
    .van-cell {
      margin-top: 16px;
      padding: 10px 0;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block1 {
      width: 85%;
      height: 300px;
      background-color: #fff;
      .qr_inner_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-weight: 600;
          font-size: 28px;
          margin: 20px 0;
        }
      }
    }
    .block {
      width: 85%;
      height: 300px;
      background-color: #fff;

      .inner_box {
        padding: 40px 0;
        text-align: center;
        padding-top: 60px;
        .el-icon-circle-check {
          color: green;
        }
        button {
          height: 40px;
          line-height: 40px;
          color: #fff;
          background: #517cfc;
          font-size: 16px;
          text-align: center;
          width: 40%;
        }
        .hang_out {
          margin-left: 10%;
          margin-top: 60px;
        }
      }
    }
  }
}
</style>